@import url('https://fonts.googleapis.com/css?family=Oswald&display=swap');
@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');

body.login {

    font-family: 'Lato', sans-serif;

    background-image: url(../images/lettow1701_background_768.jpg);
    background-size: cover;
    background-position: center center;

    div#login form {
        margin-top: 0;
        background-color: #FFFFFF;
        position: relative;

        input {
            &:focus {
                border-color: rgba(168, 134, 72, .7);
                box-shadow: 0 0 0 1px rgba(168, 134, 72, .7);
                outline: 2px solid transparent;
            }
        }

        .button-primary {
            background-color: rgba(168, 134, 72, 1);
            border-color: rgba(188, 132, 32, 1);
            &:focus {
                box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(168, 134, 72, .7);
            }
        }

        .wp-pwd {
            .wp-hide-pw {
                color: rgba(79, 57, 17, 1);
            }
        }

    }

    div#login h1 {
        background-color: rgba(0, 0, 0, .8);
        a {
           background-image: url(../images/logo_lettow.svg);
           display: block;
           width: 100%;
           height: 63px;
           overflow: hidden;
           background-position: center center;
           background-size: 113px 63px;
           background-repeat: no-repeat;
           padding: 24px 0;
           margin-bottom: 0;
           z-index: 50;
           position: relative;
       }
   }

    #nav,
    #backtoblog {
        margin: 0;
        padding: 24px;
        background-color: #f1f1f1;
    }

    #nav {
        padding-bottom: 12px;
        display: inline;
        float: right;
        padding-top: 18px;
    }

    #backtoblog {
        padding-top: 18px;
        padding-bottom: 12px;
    }


    div#login #loginform {
        // background: #165B33;

        background-color: rgba(255, 255, 255, .8);

        border-left: 0;
        border-right: 0;

        input {
            border-radius: 0px;
            // background-color: #EA4630;
        }
    }

}


/*
@media screen and (min-width: 769px) and (max-width: 992px) {
    body.login {
        background-image: url(../images/lettow1701_background_992.jpg);
    }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
    body.login {
        background-image: url(../images/lettow1701_background_1200.jpg);
    }
}

@media screen and (min-width: 1200px) {
    body.login {
        background-image: url(../images/lettow1701_background_1920.jpg);
    }
} */
